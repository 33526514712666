import { Button, message, notification } from 'antd'
// @ts-ignore
import firebase from 'firebase/app'
import { getLocale, setLocale, useIntl } from 'umi'
import defaultSettings from '../config/defaultSettings'
import BigNumber from 'bignumber.js'
BigNumber.config({
    FORMAT: {
        prefix: '',
        decimalSeparator: '.',
        groupSeparator: ',',
        groupSize: 3,
        secondaryGroupSize: 0,
        fractionGroupSeparator: ' ',
        fractionGroupSize: 0,
        suffix: '',
    },
})

const { pwa } = defaultSettings
const isHttps = document.location.protocol === 'https:'

const firebaseConfig = {
    apiKey: 'AIzaSyASA3zHH3gKMYVtKEcwVzPlSvqcC2_yHnU',
    authDomain: 'acheckin-project.firebaseapp.com',
    databaseURL: 'https://acheckin-project.firebaseio.com',
    projectId: 'acheckin-project',
    storageBucket: 'acheckin-project.appspot.com',
}

firebase.initializeApp(firebaseConfig)

if (!['vi-VN', 'en-US'].includes(getLocale())) setLocale('vi-VN')

const umiLocale = localStorage.getItem('umi_locale')
if (umiLocale === null) {
    setLocale('vi-VN')
} else {
    setLocale(getLocale())
}

// if pwa is true
if (pwa) {
    // Notify user if offline now
    window.addEventListener('sw.offline', () => {
        message.warning(useIntl().formatMessage({ id: 'app.pwa.offline' }))
    })

    // Pop up a prompt on the page asking the user if they want to use the latest version
    window.addEventListener('sw.updated', (event: Event) => {
        const e = event as CustomEvent
        const reloadSW = async () => {
            // Check if there is sw whose state is waiting in ServiceWorkerRegistration
            // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration
            const worker = e.detail && e.detail.waiting
            if (!worker) {
                return true
            }
            // Send skip-waiting event to waiting SW with MessageChannel
            await new Promise((resolve, reject) => {
                const channel = new MessageChannel()
                channel.port1.onmessage = msgEvent => {
                    if (msgEvent.data.error) {
                        reject(msgEvent.data.error)
                    } else {
                        resolve(msgEvent.data)
                    }
                }
                worker.postMessage({ type: 'skip-waiting' }, [channel.port2])
            })
            // Refresh current page to use the updated HTML and other assets after SW has skiped waiting
            // @ts-ignore
            window.location.reload(true)
            return true
        }
        const key = `open${Date.now()}`
        const btn = (
            <Button
                type="primary"
                onClick={() => {
                    notification.close(key)
                    reloadSW()
                }}
            >
                {useIntl().formatMessage({ id: 'app.pwa.serviceworker.updated.ok' })}
            </Button>
        )
        notification.open({
            message: useIntl().formatMessage({ id: 'app.pwa.serviceworker.updated' }),
            description: useIntl().formatMessage({ id: 'app.pwa.serviceworker.updated.hint' }),
            btn,
            key,
            onClose: async () => null,
        })
    })
} else if ('serviceWorker' in navigator && isHttps) {
    // unregister service worker
    const { serviceWorker } = navigator
    if (serviceWorker.getRegistrations) {
        serviceWorker.getRegistrations().then(sws => {
            sws.forEach(sw => {
                sw.unregister()
            })
        })
    }
    serviceWorker.getRegistration().then(sw => {
        if (sw) sw.unregister()
    })

    // remove all caches
    // @ts-ignore
    if (window.caches && window.caches.keys) {
        caches.keys().then(keys => {
            keys.forEach(key => {
                caches.delete(key)
            })
        })
    }
}

export default {
    'menu.home': 'Trang chủ',
    'menu.dashboard': 'Dashboard',
    'menu.customer.list': 'Thông tin khách hàng',
    'menu.customer': 'Thông tin khách hàng',
    'menu.customer_detail': 'Chi tiết Khách hàng',
    'menu.customer.overview': 'Overview',
    'menu.customer.game_play': 'Game đã chơi',
    'menu.customer.ticket': 'Ticket',
    'menu.customer.payment': 'Payment',
    'menu.customer.note': 'Note',
    'menu.dashboard.player_metrics': 'Players Metrics',
    'menu.dashboard.monetization_metrics': 'Monetization Metrics',
    'menu.dashboard.acquisition_metrics': 'Acquisition Metrics',
    'menu.content_manager': 'Quản lý bài viết',
    'menu.content_management.list': 'Danh sách bài viết',
    'menu.content_management.video': 'Danh sách video',
    'menu.content_management.broadcast': 'Quản lý broadcast',
    'menu.content_management.create_broadcast': 'Tạo broadcast',
    'menu.ticket_management': 'Ticket Management',
    'menu.automation_marketing': 'Automation Marketing',
    'menu.setting': 'Cài đặt',
    'menu.sandbox': 'Sandbox Mini App'
}

// @ts-nocheck

import HomeOutlined from '@ant-design/icons/HomeOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import AppstoreAddOutlined from '@ant-design/icons/AppstoreAddOutlined';
import LayoutOutlined from '@ant-design/icons/LayoutOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import ApartmentOutlined from '@ant-design/icons/ApartmentOutlined'

export default {
  HomeOutlined,
UserOutlined,
AppstoreAddOutlined,
LayoutOutlined,
SettingOutlined,
ApartmentOutlined
}
    
import { get } from 'lodash'
import { getLocale } from 'umi'

class ErrorUtils {
    parseError = (error: any) => {
        const errorMessage = get(error, 'message')
        const defaultMessage =
            getLocale() !== 'vi-VN'
                ? 'Some thing went wrong. Please try again later'
                : 'Đã có lỗi xảy ra. Vui lòng thử lại sau'

        if (errorMessage === 'Failed to fetch') {
            return defaultMessage
        }

        if (errorMessage) {
            return errorMessage
        }

        return defaultMessage
    }
}

export default new ErrorUtils()

// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/app/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/user",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__AuthenLayout' */'/app/src/layouts/AuthenLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "name": "login",
        "path": "/user/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Login' */'/app/src/pages/Login'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "login",
        "path": "/user/login-callback",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Login__Callback' */'/app/src/pages/Login/Callback'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/user/*",
        "redirect": "/user/login",
        "exact": true
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SecurityLayout' */'/app/src/layouts/SecurityLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'/app/src/layouts/BasicLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/",
            "redirect": "/dashboard",
            "exact": true
          },
          {
            "path": "/dashboard",
            "name": "dashboard",
            "icon": "HomeOutlined",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard' */'/app/src/pages/Dashboard'), loading: LoadingComponent}),
            "authority": [
              "DASHBOARD_PLAYER_METRIC",
              "DASHBOARD_MONETIZATION_METRIC",
              "DASHBOARD_ACQUISITION_METRIC"
            ],
            "routes": [
              {
                "path": "/dashboard",
                "redirect": "/dashboard/player-metrics",
                "exact": true
              },
              {
                "path": "/dashboard/player-metrics",
                "name": "player_metrics",
                "icon": "HomeOutlined",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__PlayerMetrics' */'/app/src/pages/Dashboard/PlayerMetrics'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/dashboard/monetization-metrics",
                "name": "monetization_metrics",
                "icon": "HomeOutlined",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__MonetizationMetrics' */'/app/src/pages/Dashboard/MonetizationMetrics'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/dashboard/acquisition-metrics",
                "name": "acquisition_metrics",
                "icon": "HomeOutlined",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__AcquisitionMetrics' */'/app/src/pages/Dashboard/AcquisitionMetrics'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              }
            ]
          },
          {
            "path": "/customer",
            "name": "customer.list",
            "icon": "UserOutlined",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Customer' */'/app/src/pages/Customer'), loading: LoadingComponent}),
            "authority": [
              "CUSTOMER_GAME",
              "CUSTOMER_NOTE",
              "CUSTOMER_PAYMENT",
              "CUSTOMER_PROFILE",
              "CUSTOMER_TICKET"
            ],
            "exact": true
          },
          {
            "name": "customer",
            "path": "/customer/:user_id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CustomerInfo' */'/app/src/pages/CustomerInfo'), loading: LoadingComponent}),
            "hideInMenu": true,
            "routes": [
              {
                "path": "/customer/:user_id",
                "redirect": "/customer/:user_id/overview",
                "exact": true
              },
              {
                "path": "/customer/:user_id/overview",
                "name": "overview",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CustomerInfo__Overview' */'/app/src/pages/CustomerInfo/Overview'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/customer/:user_id/game-play",
                "name": "game_play",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CustomerInfo__GamePlay' */'/app/src/pages/CustomerInfo/GamePlay'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/customer/:user_id/ticket",
                "name": "ticket",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CustomerInfo__Ticket' */'/app/src/pages/CustomerInfo/Ticket'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/customer/:user_id/payment",
                "name": "payment",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CustomerInfo__Payment' */'/app/src/pages/CustomerInfo/Payment'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/customer/:user_id/note",
                "name": "note",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CustomerInfo__Note' */'/app/src/pages/CustomerInfo/Note'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              }
            ]
          },
          {
            "path": "/ticket",
            "name": "ticket_management",
            "icon": "AppstoreAddOutlined",
            "authority": [
              "CUSTOMER_TICKET"
            ],
            "routes": [
              {
                "path": "/ticket",
                "redirect": "/ticket/call",
                "exact": true
              },
              {
                "path": "/ticket/call",
                "name": "call",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Call' */'/app/src/pages/TicketManagement/Call'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/ticket/call/edit/:id",
                "name": "call.edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Call__DetailPage' */'/app/src/pages/TicketManagement/Call/DetailPage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ticket/direct-payment",
                "name": "direct-payment",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__DirectPayment' */'/app/src/pages/TicketManagement/DirectPayment'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/ticket/direct-payment/create",
                "name": "direct-payment.create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__DirectPayment__CreatePage' */'/app/src/pages/TicketManagement/DirectPayment/CreatePage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ticket/direct-payment/edit/:id",
                "name": "direct-payment.edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__DirectPayment__DetailPage' */'/app/src/pages/TicketManagement/DirectPayment/DetailPage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ticket/compensation",
                "name": "compensation",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Compensation' */'/app/src/pages/TicketManagement/Compensation'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/ticket/compensation/create",
                "name": "compensation.create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Compensation__CreatePage' */'/app/src/pages/TicketManagement/Compensation/CreatePage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ticket/compensation/edit/:id",
                "name": "compensation.edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Compensation__DetailPage' */'/app/src/pages/TicketManagement/Compensation/DetailPage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ticket/support",
                "name": "support",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Support' */'/app/src/pages/TicketManagement/Support'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/ticket/support/edit/:id",
                "name": "support.edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Support__DetailPage' */'/app/src/pages/TicketManagement/Support/DetailPage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ticket/local",
                "name": "local",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Local' */'/app/src/pages/TicketManagement/Local'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/ticket/local/create",
                "name": "local.create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Local__CreatePage' */'/app/src/pages/TicketManagement/Local/CreatePage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ticket/local/edit/:id",
                "name": "local.edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Local__DetailPage' */'/app/src/pages/TicketManagement/Local/DetailPage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ticket/vip-support",
                "name": "vip-support",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__VipSupport' */'/app/src/pages/TicketManagement/VipSupport'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/ticket/vip-support/edit/:id",
                "name": "vip-support.edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__VipSupport__DetailPage' */'/app/src/pages/TicketManagement/VipSupport/DetailPage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ticket/vip",
                "name": "vip",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Vip' */'/app/src/pages/TicketManagement/Vip'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/ticket/vip/edit/:id",
                "name": "vip.edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Vip__DetailPage' */'/app/src/pages/TicketManagement/Vip/DetailPage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ticket/vip/create",
                "name": "vip.create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Vip__CreatePage' */'/app/src/pages/TicketManagement/Vip/CreatePage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              }
            ]
          },
          {
            "name": "cms",
            "path": "/cms",
            "icon": "LayoutOutlined",
            "authority": [
              "ARTICLE_POST",
              "ARTICLE_VIDEO"
            ],
            "routes": [
              {
                "path": "/cms",
                "redirect": "/cms/article-management",
                "exact": true
              },
              {
                "name": "article.management",
                "path": "/cms/article-management",
                "routes": [
                  {
                    "path": "/cms/article-management",
                    "redirect": "/cms/article-management/content",
                    "exact": true
                  },
                  {
                    "path": "/cms/article-management/content",
                    "name": "content",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__ArticleManagement__Content' */'/app/src/pages/Cms/ArticleManagement/Content'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/cms/article-management/content/create",
                    "name": "content.create",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__ArticleManagement__Content__DetailPage' */'/app/src/pages/Cms/ArticleManagement/Content/DetailPage'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/cms/article-management/content/edit/:id",
                    "name": "content.edit",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__ArticleManagement__Content__DetailPage' */'/app/src/pages/Cms/ArticleManagement/Content/DetailPage'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/cms/article-management/video",
                    "name": "video",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__ArticleManagement__Video' */'/app/src/pages/Cms/ArticleManagement/Video'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/cms/article-management/video/create",
                    "name": "video.create",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__ArticleManagement__Video__DetailPage' */'/app/src/pages/Cms/ArticleManagement/Video/DetailPage'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/cms/article-management/video/edit/:id",
                    "name": "video.edit",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__ArticleManagement__Video__DetailPage' */'/app/src/pages/Cms/ArticleManagement/Video/DetailPage'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "exact": true
                  }
                ]
              },
              {
                "path": "/cms/game-management",
                "name": "cms.game.management",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__GameManagement' */'/app/src/pages/Cms/GameManagement'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/cms/game-management/create",
                "name": "game.management.create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__GameManagement__DetailPage' */'/app/src/pages/Cms/GameManagement/DetailPage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/cms/game-management/edit/:id",
                "name": "game.management.update",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__GameManagement__DetailPage' */'/app/src/pages/Cms/GameManagement/DetailPage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/cms/event-management",
                "name": "cms.event.management",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__EventManagement' */'/app/src/pages/Cms/EventManagement'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/cms/event-management/create",
                "name": "event.management.create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__EventManagement__DetailPage' */'/app/src/pages/Cms/EventManagement/DetailPage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/cms/event-management/edit/:id",
                "name": "event.management.update",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__EventManagement__DetailPage' */'/app/src/pages/Cms/EventManagement/DetailPage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/cms/giftcode-management",
                "name": "cms.giftcode",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__GiftCodeManagement' */'/app/src/pages/Cms/GiftCodeManagement'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/cms/giftcode-management/detail/:id",
                "name": "cms.giftcode.detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__GiftCodeManagement__ListGiftCode' */'/app/src/pages/Cms/GiftCodeManagement/ListGiftCode'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/cms/lucky-wheel",
                "name": "cms.lucky-wheel",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__LuckyWheel' */'/app/src/pages/Cms/LuckyWheel'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/cms/lucky-wheel",
                    "redirect": "/cms/lucky-wheel/history",
                    "exact": true
                  },
                  {
                    "path": "/cms/lucky-wheel/history",
                    "name": "history",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__LuckyWheel__History' */'/app/src/pages/Cms/LuckyWheel/History'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/cms/lucky-wheel/setting",
                    "name": "setting",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__LuckyWheel__Setting' */'/app/src/pages/Cms/LuckyWheel/Setting'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/cms/lucky-wheel/checkin-setting",
                    "name": "checkin_setting",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__LuckyWheel__CheckinSetting' */'/app/src/pages/Cms/LuckyWheel/CheckinSetting'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/cms/lucky-wheel/checkin-reward-settings",
                    "name": "checkin_reward_settings",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__LuckyWheel__CheckinRewardSetting' */'/app/src/pages/Cms/LuckyWheel/CheckinRewardSetting'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "exact": true
                  }
                ]
              }
            ]
          },
          {
            "name": "system",
            "path": "/system",
            "icon": "SettingOutlined",
            "routes": [
              {
                "path": "/system",
                "redirect": "/system/permission",
                "exact": true
              },
              {
                "name": "permission",
                "path": "/system/permission",
                "icon": "Apartment",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Permission' */'/app/src/pages/Permission'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "permission_create",
                "path": "/system/permission/create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PermissionDetail' */'/app/src/pages/PermissionDetail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "name": "permission_detail",
                "path": "/system/permission/update/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PermissionDetail' */'/app/src/pages/PermissionDetail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "name": "member_list",
                "path": "/system/permission/member/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PermissionMemberList' */'/app/src/pages/PermissionMemberList'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "name": "administrator",
                "path": "/system/administrator",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setting__Administrator' */'/app/src/pages/Setting/Administrator'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "path": "/user",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__AuthenLayout' */'/app/src/layouts/AuthenLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "name": "login",
        "path": "/user/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Login' */'/app/src/pages/Login'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "login",
        "path": "/user/login-callback",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Login__Callback' */'/app/src/pages/Login/Callback'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/user/*",
        "redirect": "/user/login",
        "exact": true
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SecurityLayout' */'/app/src/layouts/SecurityLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'/app/src/layouts/BasicLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/",
            "redirect": "/dashboard",
            "exact": true
          },
          {
            "path": "/dashboard",
            "name": "dashboard",
            "icon": "HomeOutlined",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard' */'/app/src/pages/Dashboard'), loading: LoadingComponent}),
            "authority": [
              "DASHBOARD_PLAYER_METRIC",
              "DASHBOARD_MONETIZATION_METRIC",
              "DASHBOARD_ACQUISITION_METRIC"
            ],
            "routes": [
              {
                "path": "/dashboard",
                "redirect": "/dashboard/player-metrics",
                "exact": true
              },
              {
                "path": "/dashboard/player-metrics",
                "name": "player_metrics",
                "icon": "HomeOutlined",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__PlayerMetrics' */'/app/src/pages/Dashboard/PlayerMetrics'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/dashboard/monetization-metrics",
                "name": "monetization_metrics",
                "icon": "HomeOutlined",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__MonetizationMetrics' */'/app/src/pages/Dashboard/MonetizationMetrics'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/dashboard/acquisition-metrics",
                "name": "acquisition_metrics",
                "icon": "HomeOutlined",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__AcquisitionMetrics' */'/app/src/pages/Dashboard/AcquisitionMetrics'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              }
            ]
          },
          {
            "path": "/customer",
            "name": "customer.list",
            "icon": "UserOutlined",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Customer' */'/app/src/pages/Customer'), loading: LoadingComponent}),
            "authority": [
              "CUSTOMER_GAME",
              "CUSTOMER_NOTE",
              "CUSTOMER_PAYMENT",
              "CUSTOMER_PROFILE",
              "CUSTOMER_TICKET"
            ],
            "exact": true
          },
          {
            "name": "customer",
            "path": "/customer/:user_id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CustomerInfo' */'/app/src/pages/CustomerInfo'), loading: LoadingComponent}),
            "hideInMenu": true,
            "routes": [
              {
                "path": "/customer/:user_id",
                "redirect": "/customer/:user_id/overview",
                "exact": true
              },
              {
                "path": "/customer/:user_id/overview",
                "name": "overview",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CustomerInfo__Overview' */'/app/src/pages/CustomerInfo/Overview'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/customer/:user_id/game-play",
                "name": "game_play",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CustomerInfo__GamePlay' */'/app/src/pages/CustomerInfo/GamePlay'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/customer/:user_id/ticket",
                "name": "ticket",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CustomerInfo__Ticket' */'/app/src/pages/CustomerInfo/Ticket'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/customer/:user_id/payment",
                "name": "payment",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CustomerInfo__Payment' */'/app/src/pages/CustomerInfo/Payment'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/customer/:user_id/note",
                "name": "note",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CustomerInfo__Note' */'/app/src/pages/CustomerInfo/Note'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              }
            ]
          },
          {
            "path": "/ticket",
            "name": "ticket_management",
            "icon": "AppstoreAddOutlined",
            "authority": [
              "CUSTOMER_TICKET"
            ],
            "routes": [
              {
                "path": "/ticket",
                "redirect": "/ticket/call",
                "exact": true
              },
              {
                "path": "/ticket/call",
                "name": "call",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Call' */'/app/src/pages/TicketManagement/Call'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/ticket/call/edit/:id",
                "name": "call.edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Call__DetailPage' */'/app/src/pages/TicketManagement/Call/DetailPage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ticket/direct-payment",
                "name": "direct-payment",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__DirectPayment' */'/app/src/pages/TicketManagement/DirectPayment'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/ticket/direct-payment/create",
                "name": "direct-payment.create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__DirectPayment__CreatePage' */'/app/src/pages/TicketManagement/DirectPayment/CreatePage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ticket/direct-payment/edit/:id",
                "name": "direct-payment.edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__DirectPayment__DetailPage' */'/app/src/pages/TicketManagement/DirectPayment/DetailPage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ticket/compensation",
                "name": "compensation",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Compensation' */'/app/src/pages/TicketManagement/Compensation'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/ticket/compensation/create",
                "name": "compensation.create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Compensation__CreatePage' */'/app/src/pages/TicketManagement/Compensation/CreatePage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ticket/compensation/edit/:id",
                "name": "compensation.edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Compensation__DetailPage' */'/app/src/pages/TicketManagement/Compensation/DetailPage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ticket/support",
                "name": "support",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Support' */'/app/src/pages/TicketManagement/Support'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/ticket/support/edit/:id",
                "name": "support.edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Support__DetailPage' */'/app/src/pages/TicketManagement/Support/DetailPage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ticket/local",
                "name": "local",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Local' */'/app/src/pages/TicketManagement/Local'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/ticket/local/create",
                "name": "local.create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Local__CreatePage' */'/app/src/pages/TicketManagement/Local/CreatePage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ticket/local/edit/:id",
                "name": "local.edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Local__DetailPage' */'/app/src/pages/TicketManagement/Local/DetailPage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ticket/vip-support",
                "name": "vip-support",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__VipSupport' */'/app/src/pages/TicketManagement/VipSupport'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/ticket/vip-support/edit/:id",
                "name": "vip-support.edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__VipSupport__DetailPage' */'/app/src/pages/TicketManagement/VipSupport/DetailPage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ticket/vip",
                "name": "vip",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Vip' */'/app/src/pages/TicketManagement/Vip'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/ticket/vip/edit/:id",
                "name": "vip.edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Vip__DetailPage' */'/app/src/pages/TicketManagement/Vip/DetailPage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ticket/vip/create",
                "name": "vip.create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketManagement__Vip__CreatePage' */'/app/src/pages/TicketManagement/Vip/CreatePage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              }
            ]
          },
          {
            "name": "cms",
            "path": "/cms",
            "icon": "LayoutOutlined",
            "authority": [
              "ARTICLE_POST",
              "ARTICLE_VIDEO"
            ],
            "routes": [
              {
                "path": "/cms",
                "redirect": "/cms/article-management",
                "exact": true
              },
              {
                "name": "article.management",
                "path": "/cms/article-management",
                "routes": [
                  {
                    "path": "/cms/article-management",
                    "redirect": "/cms/article-management/content",
                    "exact": true
                  },
                  {
                    "path": "/cms/article-management/content",
                    "name": "content",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__ArticleManagement__Content' */'/app/src/pages/Cms/ArticleManagement/Content'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/cms/article-management/content/create",
                    "name": "content.create",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__ArticleManagement__Content__DetailPage' */'/app/src/pages/Cms/ArticleManagement/Content/DetailPage'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/cms/article-management/content/edit/:id",
                    "name": "content.edit",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__ArticleManagement__Content__DetailPage' */'/app/src/pages/Cms/ArticleManagement/Content/DetailPage'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/cms/article-management/video",
                    "name": "video",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__ArticleManagement__Video' */'/app/src/pages/Cms/ArticleManagement/Video'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/cms/article-management/video/create",
                    "name": "video.create",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__ArticleManagement__Video__DetailPage' */'/app/src/pages/Cms/ArticleManagement/Video/DetailPage'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/cms/article-management/video/edit/:id",
                    "name": "video.edit",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__ArticleManagement__Video__DetailPage' */'/app/src/pages/Cms/ArticleManagement/Video/DetailPage'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "exact": true
                  }
                ]
              },
              {
                "path": "/cms/game-management",
                "name": "cms.game.management",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__GameManagement' */'/app/src/pages/Cms/GameManagement'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/cms/game-management/create",
                "name": "game.management.create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__GameManagement__DetailPage' */'/app/src/pages/Cms/GameManagement/DetailPage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/cms/game-management/edit/:id",
                "name": "game.management.update",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__GameManagement__DetailPage' */'/app/src/pages/Cms/GameManagement/DetailPage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/cms/event-management",
                "name": "cms.event.management",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__EventManagement' */'/app/src/pages/Cms/EventManagement'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/cms/event-management/create",
                "name": "event.management.create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__EventManagement__DetailPage' */'/app/src/pages/Cms/EventManagement/DetailPage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/cms/event-management/edit/:id",
                "name": "event.management.update",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__EventManagement__DetailPage' */'/app/src/pages/Cms/EventManagement/DetailPage'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/cms/giftcode-management",
                "name": "cms.giftcode",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__GiftCodeManagement' */'/app/src/pages/Cms/GiftCodeManagement'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/cms/giftcode-management/detail/:id",
                "name": "cms.giftcode.detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__GiftCodeManagement__ListGiftCode' */'/app/src/pages/Cms/GiftCodeManagement/ListGiftCode'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/cms/lucky-wheel",
                "name": "cms.lucky-wheel",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__LuckyWheel' */'/app/src/pages/Cms/LuckyWheel'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/cms/lucky-wheel",
                    "redirect": "/cms/lucky-wheel/history",
                    "exact": true
                  },
                  {
                    "path": "/cms/lucky-wheel/history",
                    "name": "history",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__LuckyWheel__History' */'/app/src/pages/Cms/LuckyWheel/History'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/cms/lucky-wheel/setting",
                    "name": "setting",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__LuckyWheel__Setting' */'/app/src/pages/Cms/LuckyWheel/Setting'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/cms/lucky-wheel/checkin-setting",
                    "name": "checkin_setting",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__LuckyWheel__CheckinSetting' */'/app/src/pages/Cms/LuckyWheel/CheckinSetting'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/cms/lucky-wheel/checkin-reward-settings",
                    "name": "checkin_reward_settings",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cms__LuckyWheel__CheckinRewardSetting' */'/app/src/pages/Cms/LuckyWheel/CheckinRewardSetting'), loading: LoadingComponent}),
                    "hideInMenu": true,
                    "exact": true
                  }
                ]
              }
            ]
          },
          {
            "name": "system",
            "path": "/system",
            "icon": "SettingOutlined",
            "routes": [
              {
                "path": "/system",
                "redirect": "/system/permission",
                "exact": true
              },
              {
                "name": "permission",
                "path": "/system/permission",
                "icon": "Apartment",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Permission' */'/app/src/pages/Permission'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "permission_create",
                "path": "/system/permission/create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PermissionDetail' */'/app/src/pages/PermissionDetail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "name": "permission_detail",
                "path": "/system/permission/update/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PermissionDetail' */'/app/src/pages/PermissionDetail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "name": "member_list",
                "path": "/system/permission/member/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PermissionMemberList' */'/app/src/pages/PermissionMemberList'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "name": "administrator",
                "path": "/system/administrator",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setting__Administrator' */'/app/src/pages/Setting/Administrator'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}

import type { Reducer, Effect } from 'umi'

import type { NoticeIconData } from '@/components/NoticeIcon'
import type { ConnectState } from './connect'

export type NoticeItem = {
    id: string
    type: string
    status: string
} & NoticeIconData

export type GlobalModelState = {
    collapsed: boolean
    notices: NoticeItem[]
}

export type GlobalModelType = {
    namespace: 'global'
    state: GlobalModelState
    effects: {
        clearNotices: Effect
        changeNoticeReadState: Effect
    }
    reducers: {
        changeLayoutCollapsed: Reducer<GlobalModelState>
        saveNotices: Reducer<GlobalModelState>
        saveClearedNotices: Reducer<GlobalModelState>
    }
}

const GlobalModel: GlobalModelType = {
    namespace: 'global',

    state: {
        collapsed: false,
        notices: [],
    },

    effects: {
        *clearNotices({ payload }, { put, select }) {
            yield put({
                type: 'saveClearedNotices',
                payload,
            })
            const count: number = yield select((state: ConnectState) => state.global.notices.length)
            const unreadCount: number = yield select(
                (state: ConnectState) => state.global.notices.filter(item => !item.read).length,
            )
            yield put({
                type: 'user/changeNotifyCount',
                payload: {
                    totalCount: count,
                    unreadCount,
                },
            })
        },
        *changeNoticeReadState({ payload }, { put, select }) {
            const notices: NoticeItem[] = yield select((state: ConnectState) =>
                state.global.notices.map(item => {
                    const notice = { ...item }
                    if (notice.id === payload) {
                        notice.read = true
                    }
                    return notice
                }),
            )

            yield put({
                type: 'saveNotices',
                payload: notices,
            })

            yield put({
                type: 'user/changeNotifyCount',
                payload: {
                    totalCount: notices.length,
                    unreadCount: notices.filter(item => !item.read).length,
                },
            })
        },
    },

    reducers: {
        changeLayoutCollapsed(
            state = { notices: [], collapsed: true },
            { payload },
        ): GlobalModelState {
            return {
                ...state,
                collapsed: payload,
            }
        },
        saveNotices(state, { payload }): GlobalModelState {
            return {
                collapsed: false,
                ...state,
                notices: payload,
            }
        },
        saveClearedNotices(
            state = { notices: [], collapsed: true },
            { payload },
        ): GlobalModelState {
            return {
                ...state,
                collapsed: false,
                notices: state.notices.filter((item): boolean => item.type !== payload),
            }
        },
    },
}

export default GlobalModel

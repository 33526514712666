export default {
    'menu.home': 'Trang chủ',
    'menu.dashboard': 'Dashboard',
    'menu.customer.list': 'Thông tin khách hàng',
    'menu.customer': 'Thông tin khách hàng',
    'menu.customer_detail': 'Chi tiết Khách hàng',
    'menu.customer.overview': 'Overview',
    'menu.customer.game_play': 'Game đã chơi',
    'menu.customer.ticket': 'Ticket',
    'menu.customer.payment': 'Payment',
    'menu.customer.note': 'Note',
    'menu.dashboard.player_metrics': 'Players Metrics',
    'menu.dashboard.monetization_metrics': 'Monetization Metrics',
    'menu.dashboard.acquisition_metrics': 'Acquisition Metrics',
    'menu.content_manager': 'Quản lý bài viết',
    'menu.content_management.list': 'Danh sách bài viết',
    'menu.content_management.video': 'Danh sách video',
    'menu.content_management.broadcast': 'Quản lý broadcast',
    'menu.content_management.create_broadcast': 'Tạo broadcast',
    'menu.ticket_management': 'Ticket Management',
    'menu.automation_marketing': 'Automation Marketing',
    'menu.setting': 'Cài đặt',
    'menu.sandbox': 'Sandbox Mini App',
    'menu.system': 'Cài đặt',
    'menu.system.permission': 'Phân quyền',
    'menu.system.permission_detail': 'Sửa quyền quản trị',
    'menu.system.member_list': 'Danh sách thành viên',
    'menu.system.permission_create': 'Thêm quyền quản trị',
    'menu.system.administrator': 'Quản trị viên',
    'menu.cms': 'CMS',
    'menu.cms.article.management': 'Quản lý bài viết',
    'menu.cms.article.management.content': 'Danh sách bài viết',
    'menu.cms.article.management.video': 'Danh sách video',
    'menu.cms.cms.game.management': 'Quản lý Game',
    'menu.cms.article.management.content.create': 'Thêm bài viết',
    'menu.cms.article.management.content.edit': 'Cập nhật bài viết',
    'menu.cms.game.management.create': 'Thêm Game',
    'menu.cms.game.management.update': 'Cập nhật Game',
    'menu.cms.cms.event.management': 'Quản lý Event',
    'menu.cms.event.management.create': 'Thêm mới event',
    'menu.cms.event.management.update': 'Cập nhật event',
    'menu.cms.article.management.video.create': 'Thêm video',
    'menu.cms.article.management.video.update': 'Cập nhật video',
    'menu.ticket_management.call': 'Cuộc gọi',
    'menu.cms.article.management.video.edit': "Cập nhật video",
    'menu.ticket_management.call.create': 'Tạo cuộc gọi',
    'menu.ticket_management.call.edit': 'Cập nhật cuộc gọi',
    'menu.ticket_management.direct-payment': "Nạp trực tiếp",
    'menu.ticket_management.compensation': 'Bù đơn',
    'menu.ticket_management.support': 'Hỗ trợ',
    'menu.ticket_management.local': 'Nội bộ',
    'menu.ticket_management.vip-support': 'Hỗ trợ VIP',
    'menu.ticket_management.vip': 'VIP',
    'menu.ticket_management.direct-payment.edit': "Cập nhật nạp trực tiếp",
    'menu.ticket_management.compensation.edit': 'Cập nhật bù đơn',
    'menu.ticket_management.support.edit': 'Cập nhật hỗ trợ',
    'menu.ticket_management.local.edit': 'Cập nhật nội bộ',
    'menu.ticket_management.vip-support.edit': 'Cập nhật hỗ trợ VIP',
    'menu.ticket_management.vip.edit': 'Cập nhật VIP',
    'menu.ticket_management.direct-payment.create': "Tạo mới nạp trực tiếp",
    'menu.ticket_management.vip.create': "Tạo mới VIP",
    'menu.ticket_management.local.create': "Tạo mới nội bộ",
    'menu.ticket_management.compensation.create': "Tạo mới bù đơn",
    'menu.cms.cms.giftcode': "Quản lý giftcode",
    'menu.cms.cms.lucky-wheel': 'Vòng quay may mắn',
    'menu.cms.cms.giftcode.detail': 'Chi tiết',
    'menu.cms.cms.lucky-wheel.history': 'Lịch sử',
    'menu.cms.cms.lucky-wheel.setting': 'Cấu hình vòng quay',
    'menu.cms.cms.lucky-wheel.checkin_setting': 'Cấu hình checkin',
    'menu.cms.cms.lucky-wheel.checkin_reward_settings': 'Cấu hình chuỗi checkin',
}
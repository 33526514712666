import RequestHelper from '@/utils/RequestHelper'
import Storage, { STORE_KEYS } from '@/utils/Storage'
import type { IIdUserType } from './types/User.type'

class AuthenStore {
    logout = async () => {
        Storage.clear()
        RequestHelper.setToken('')
    }

    refreshToken = async () => {
        const userIdInfo: IIdUserType = Storage.get(STORE_KEYS.USER_ID_INFO)
        const { refresh_token } = userIdInfo

        const res = await RequestHelper.post({
            url: '/v1/external/auth/refresh-token',
            data: { refresh_token },
        })

        if (res && res.data) {
            const data = res.data as IIdUserType
            Storage.set(STORE_KEYS.USER_ID_INFO, data)
            return data
        } else {
            throw new Error(res.data.message)
        }
    }

    getCurrentUser = () => {
        const userIdInfo: IIdUserType = Storage.get(STORE_KEYS.USER_ID_INFO)
        const accessToken = Storage.get(STORE_KEYS.USER_TOKEN)
        if (!userIdInfo?.access_token) return false
        try {
            RequestHelper.setToken(accessToken)

            return userIdInfo
        } catch (error) {
            return false
        }
    }
}

export default new AuthenStore()

import type { Settings as ProSettings } from '@ant-design/pro-layout'

type DefaultSettings = Partial<ProSettings> & {
    pwa: boolean
}

const proSettings: DefaultSettings = {
    navTheme: 'dark',
    // 拂晓蓝
    primaryColor: '#00AC4F',
    layout: 'side',
    contentWidth: 'Fluid',
    fixedHeader: true,
    fixSiderbar: true,
    colorWeak: false,
    // title: 'WS Event',
    pwa: false,
    iconfontUrl: '',
}

export const defaultTheme = {
    'primary-color': '#00AC4F',
    'secondary-color': '#9197B3',
    'white-color': '#fff',
    'link-color': '#00AC4F',
    'success-color': '#52c41a',
    'warning-color': '#faad14',
    'error-color': '#f5222d',
    'font-size-base': '13px',
    'heading-color': 'rgba(0, 0, 0, 0.85)',
    'text-color': 'rgba(0, 0, 0, 0.65)',
    'text-color-secondary': 'rgba(0, 0, 0, 0.45)',
    'disabled-color': 'rgba(0, 0, 0, 0.25)',
    'border-radius-base': '6px',
    'border-color-base': '#d9d9d9',
    'layout-header-background': '#FFF',
    'menu-dark-item-active-bg': '#13B453',
    'layout-header-height': '68px',
}

export type { DefaultSettings }

export default proSettings
